<template>
    <b-modal
        v-bind="$attrs"
        size="sm"
        centered
    >
        <template #modal-header>
            <h2> {{ $t('programs.create.title') }} </h2>
        </template>

        <template #default>
            <b-form @submit.prevent="createProgram()">
                <input
                    type="submit"
                    class="d-none"
                >

                <multi-language-tabs :include-locales="['nl']">
                    <template #default="{locale}">
                        <b-form-group
                            :invalid-feedback="program.getErrors('title', locale)"
                            :state="!program.getErrors('title', locale)"
                        >
                            <b-input
                                v-model="program.title[locale]"
                                :placeholder="program.getTranslation('title', 'placeholder')"
                                autofocus
                            />
                        </b-form-group>
                    </template>
                </multi-language-tabs>
            </b-form>
        </template>

        <template #modal-footer="{cancel}">
            <b-btn
                class="btn-min-width"
                variant="light"
                @click="cancel()"
            >
                {{ $t('buttons.cancel') }}
            </b-btn>

            <btn-resource
                variant="dark"
                class="btn-min-width"
                :resource="program"
                @click="createProgram()"
            >
                {{ $t('programs.create.title') }}
            </btn-resource>
        </template>
    </b-modal>
</template>

<script>
import MultiLanguageTabs from '@/components/common/MultiLanguageTabs';
import {Program} from '@/models/Program';
import {detainFeedback} from '@/library/helpers';

export default {
    name: 'ProgramModalCreate',
    components: {MultiLanguageTabs},
    data: () => ({
        program: new Program(),
    }),
    methods: {
        async createProgram() {
            await this.program.save();

            detainFeedback(() => {
                // Navigate to the program's edit page.
                this.$router.push(this.program.getLocation('edit'));
            });
        },
    },
};
</script>
