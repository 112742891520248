import {User} from '@/models/User';

/**
 *
 * @param {i18n.t} $t
 * @param {User} me
 */
export function coachesIntroduction($t, me) {
    return [
        {
            identifier: 'welcome',
            markdown: $t('introductions.platform.welcome'),
        },
        {
            identifier: 'profile',
            target: 'profile',
            markdown: $t('introductions.platform.profile'),
        },
        {
            identifier: 'navigation',
            target: 'navigation',
            markdown: $t('introductions.platform.navigation'),
        },
        {
            identifier: 'finish',
            target: 'create-program',
            markdown: $t('introductions.platform.finish.body', {name: me.name}),
            actions: [
                {
                    /**
                     * @param {Vue<TourStepMixin>} vue
                     */
                    handler: async(vue) => {
                        await vue.finish();
                        vue.$bvModal.show('modal-create-program');
                    },
                    variant: 'primary',
                    text: $t('introductions.platform.finish.actions.createProgram'),
                },
            ],
        },
    ];
}
