<template>
    <canvas />
</template>

<script>
export default {
    name: 'IntroductionBackdrop',
    props: {
        element: {
            type: Element,
            required: true,
        },
        targeted: {
            type: Boolean,
            required: true,
        },
    },
    watch: {
        element() {
            this.onResize();
        },
    },
    mounted() {
        this.onResize();
        window.addEventListener('resize', this.onResize.bind(this));
    },
    beforeDestroy() {
        window.addEventListener('resize', this.onResize.bind(this));
    },
    methods: {
        onResize() {
            /**
             * @type {HTMLCanvasElement}
            */
            const canvas = this.$el;
            // Resize the canvas to be the same height and width as the window.
            canvas.width = window.innerWidth;
            canvas.height = window.innerHeight;
            // Get the context (With opacity enabled)
            const context = canvas.getContext('2d', {alpha: true});

            // If targeted is true, find the bounds of the targeted element and fill the canvas with a cut-out
            // Else just fill the entire canvas.
            if (this.targeted) {
                try {
                    const target = this.element;
                    // Get the bounds of the targeted element.
                    const gap = target.getBoundingClientRect();
                    // Grab the border radius of the targeted element
                    const style = getComputedStyle(target);
                    const cornerRadius = Number.parseFloat(style.borderRadius.replace(/px$/, ''));
                    // Fill the canvas with a cut-out for the targeted element.
                    this.draw(context, gap, isNaN(cornerRadius) ? 0 : cornerRadius);
                } catch (e) {
                    console.error(e);
                }
            } else {
                // Fill the canvas without a cut-out
                this.draw(context, undefined);
            }
        },

        /**
         * Creates a rounded rectangle path.
         * @param {CanvasRenderingContext2D} ctx
         * @param x
         * @param y
         * @param width
         * @param height
         * @param radius
         */
        roundRect(ctx, x, y, width, height, radius) {
            ctx.moveTo(x + radius, y);
            ctx.lineTo(x + width - radius, y);
            ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
            ctx.lineTo(x + width, y + height - radius);
            ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
            ctx.lineTo(x + radius, y + height);
            ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
            ctx.lineTo(x, y + radius);
            ctx.quadraticCurveTo(x, y, x + radius, y);
        },

        /**
         * Fills the entire screen with a translucent dark color with a gap (If specified)
         * @param {CanvasRenderingContext2D} ctx
         * @param {DOMRect?} gap - The cut-out area
         * @param cornerRadius
         */
        draw(ctx, gap, cornerRadius = 0) {
            if (gap) {
                ctx.globalCompositeOperation = 'dest-over';
                ctx.fillStyle = 'rgba(0, 0, 0, 1)';
                // Fill the entire canvas with solid black.
                ctx.fillRect(0, 0, ctx.canvas.width, ctx.canvas.height);

                ctx.beginPath();
                // Make the rounded rectangle path
                this.roundRect(
                    ctx,
                    Math.ceil(gap.left),
                    Math.ceil(gap.top),
                    Math.floor(gap.width),
                    Math.floor(gap.height),
                    Math.ceil(cornerRadius),
                );
                ctx.globalCompositeOperation = 'source-in';
                // Fill the rounded rectangle path (Note the `source-in` composite operation, means it will actually clear the pixels in this path)
                ctx.fill();
                ctx.closePath();
            }

            ctx.globalCompositeOperation = 'source-out';
            ctx.fillStyle = 'rgba(0, 0, 0, 0.5)';
            // Fill the canvas with a translucent black color (Only the solid black pixels will be replaced)
            ctx.fillRect(0, 0, ctx.canvas.width, ctx.canvas.height);
        },
    },
};
</script>
