<template>
    <b-table
        v-infinite-scroll.document="fetchCollection"
        :fields="fields"
        :sort-by.sync="collection.sortBy"
        :sort-desc.sync="collection.sortDesc"
        :items="collection.models"
        :busy="collection.loading"
        no-local-sorting
        hover
        responsive
        table-class="table-click"
        show-empty
        @row-clicked="$emit('click-row', $event)"
    >
        <!-- Table busy state -->
        <template #table-busy>
            <spinner-block />
        </template>

        <template #cell(privacy)="{item}">
            {{ PostPrivacy.$t(item.privacy) }}
        </template>
    </b-table>
</template>

<script>
import Collection from '@/models/vue-mc/Collection';
import PostPrivacy from '@/library/enumerations/PostPrivacy';
import QueryableCollection from '@/components/common/mixins/QueryableCollection';

export default {
    name: 'ProgramsTable',
    mixins: [QueryableCollection],
    props: {
        /**
         * The collection to use for pagination.
         */
        collection: {
            type: Collection,
            required: true,
        },
    },
    data() {
        return {
            PostPrivacy: PostPrivacy,
            fields: [
                {
                    key: 'title',
                    label: this.$t('programs.model.title.label'),
                    tdClass: 'font-weight-bold',
                    sortable: true,
                },
                {
                    key: 'privacy',
                    label: this.$t('programs.model.privacy.label'),
                    sortable: true,
                },
                {
                    key: 'postUsersCount',
                    label: this.$t('clients.clients'),
                },
                {
                    key: 'chaptersCount',
                    label: this.$t('programs.model.chapters.label'),
                },
                {
                    key: 'chaptersPostsCount',
                    label: this.$t('common.words.media'),
                },
            ],
        };
    },
    async mounted() {
        await this.fetchCollectionUntilScrollable();
    },
};
</script>
