<template>
    <div
        v-if="Tour.currentTourIdentifier === tourName && !Tour.finished"
        class="introduction-overlay"
    >
        <introduction-backdrop
            :element="Tour.step.element"
            :targeted="Tour.step.targeted"
        />

        <div class="card-container d-flex justify-content-center align-items-center">
            <introduction-card
                :element="Tour.step.element"
                :targeted="Tour.step.targeted"
            >
                <component
                    :is="Tour.step.component"
                    v-if="data"
                    :step="data"
                />
            </introduction-card>
        </div>
    </div>
</template>

<script>
import IntroductionBackdrop from '@/components/common/introduction/Backdrop';
import IntroductionCard from '@/components/common/introduction/Card';
import {Tour} from '@/library/directives/Tour';

export default {
    name: 'IntroductionOverlay',
    components: {IntroductionBackdrop, IntroductionCard},
    props: {
        tourName: {
            type: String,
            required: true,
        },
        stepData: {
            type: Array,
            required: true,
        },
    },
    data: () => ({
        Tour,
    }),
    computed: {
        data() {
            const step = Tour.step;
            if (step !== undefined) {
                return this.stepData.find(d => d.identifier === step.identifier);
            }
            return undefined;
        },
    },
};
</script>

<style lang="scss">
.introduction-overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1030;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    animation: enter .5s;
    animation-fill-mode: forwards;

    canvas,
    .card-container {
        position: inherit;
        top: inherit;
        left: inherit;
        width: inherit;
        height: inherit;
    }

    @keyframes enter {
        from { opacity: 0; }
        to { opacity: 1; }
    }
}
</style>
