<template>
    <div
        v-tour="{tour: 'coach-intro', identifier: 'welcome', priority: 10, targeted: false}"
        class="h-100"
    >
        <introduction-overlay
            tour-name="coach-intro"
            :step-data="coachesIntroductionSteps"
        />

        <portal to="layout-default-header">
            <header-index :title="$t('programs.index.title')">
                <input-search
                    v-model="programs.searchQuery"
                    class="mr-3"
                />

                <template #create-new>
                    <b-btn
                        v-b-modal="'modal-create-program'"
                        v-tour="{tour: 'coach-intro', identifier: 'finish', priority: 40}"
                        class="btn-min-width"
                        variant="dark"
                    >
                        {{ $t('programs.create.title') }}
                    </b-btn>

                    <program-modal-create id="modal-create-program" />
                </template>
            </header-index>
        </portal>

        <div
            v-if="noPrograms"
            class="h-100 d-flex flex-column justify-content-center align-items-center"
        >
            <h5>{{ $t('programs.messages.createFirstProgram') }}</h5>

            <b-link
                v-b-modal="'modal-create-program'"
                class="text-primary mt-2"
            >
                {{ $t('programs.create.title') }}
            </b-link>
        </div>

        <programs-table
            v-show="!noPrograms"
            :collection="programs"
            :search="programs.searchQuery"
            @click-row="navigateToProgram"
        />
    </div>
</template>

<script>
import IntroductionOverlay from '@/components/common/introduction/Overlay';
import {Tour} from '@/library/directives/Tour';
import {coachesIntroduction} from '@/library/introduction/CoachesIntroduction';
import {User} from '@/models/User';
import {Programs} from '@/models/Program';
import HeaderIndex from '@/components/layouts/HeaderIndex';
import ProgramsTable from '@/components/users/programs/Table';
import InputSearch from '@/components/common/form/InputSearch';
import ProgramModalCreate from '@/components/users/programs/ModalCreate';

export default {
    name: 'ProgramsIndex',
    components: {IntroductionOverlay, InputSearch, ProgramModalCreate, ProgramsTable, HeaderIndex},
    props: {
        user: {
            type: User,
            required: true,
        },
    },
    data: function() {
        return {
            // TODO: Not sure what the best way would be to define the tour content
            coachesIntroductionSteps: coachesIntroduction(this.$t.bind(this), this.$me),
            /**
             * A collection of programs.
             */
            programs: new Programs([], {
                routePrefix: this.user.getFetchURL(),
            }),
        };
    },
    computed: {
        noPrograms() {
            return !this.programs.loading && !this.programs.searchQuery && this.programs.isEmpty();
        },
    },
    mounted() {
        // If the coach was redirected here from the registration page, show them a short introduction about the platform.
        if (this.$route.query.origin === 'register') {
            Tour.start('coach-intro');
            Tour.$on('finished', this.onIntroductionFinished);
        }
    },
    methods: {
        /**
         * Navigates to the program edit page.
         *
         * @param program
         */
        navigateToProgram(program) {
            this.$router.push(program.getLocation('edit'));
        },
        /**
        * Removes the `origin` query from the current route.
        */
        onIntroductionFinished(name) {
            // Only remove the origin query when the "coach-intro" tour finished.
            if (name === 'coach-intro') {
                this.$router.replace({
                    ...this.$route,
                    query: {
                        ...this.$route.query,
                        origin: undefined,
                    },
                });
            }
        },
    },
};
</script>
