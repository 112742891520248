<template>
    <div
        class="introduction-card d-inline-block px-5 py-4 bg-white rounded"
        :class="{'position-fixed': targeted && position, [mountType]: true}"
        :style="position ? {top: `${position.top}px`, left: `${position.left}px`} : {}"
    >
        <svg
            v-if="targeted && position"
            viewBox="0 0 50 25"
            :style="position ? {left: `${position.pointerOffset * 100}%`} : {}"
        >
            <path
                v-if="position && position.mountBelow"
                d="M0 25 L50 25 L25 0 Z"
            />
            <path
                v-else
                d="M0 0 L50 0 L25 25 Z"
            />
        </svg>

        <slot />
    </div>
</template>

<script>

export default {
    name: 'IntroductionCard',
    props: {
        element: {
            type: Element,
            required: true,
        },
        targeted: {
            type: Boolean,
            required: true,
        },
    },
    data: () => ({
        position: null,
    }),
    computed: {
        mountType() {
            const mountBelow = this.position ? this.position.mountBelow : undefined;
            switch (mountBelow) {
                case true: return 'mount-bottom';
                case false: return 'mount-top';
                default: return '';
            }
        },
    },
    watch: {
        element() {
            this.onResize();
        },
    },
    mounted() {
        this.onResize();
        window.addEventListener('resize', this.onResize.bind(this));
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.onResize.bind(this));
    },
    methods: {
        isHidden(bounds) {
            return bounds.width <= 0 && bounds.height <= 0;
        },
        onResize() {
            if (!this.targeted) {
                this.position = null;
                return;
            }

            const self = this.$el;
            if (!(self instanceof Element)) {
                this.position = null;
                return;
            }

            this.position = this.calculatePosition(this.element, self);
        },

        /**
         *
         * @param {Element} target
         * @param {Element} self
        */
        calculatePosition(target, self) {
            const spacing = 30;
            const targetBounds = target.getBoundingClientRect();
            if (this.isHidden(targetBounds)) {
                return null;
            }

            const selfBounds = self.getBoundingClientRect();
            const windowHeight = window.innerHeight;
            const windowWidth = window.innerWidth;

            const horizontalCenter = targetBounds.left + (targetBounds.width / 2);
            const selfHorizontalCenter = selfBounds.width / 2;

            // Set left to the targets horizontal center point, unless it overflows the window bounds...
            let left = horizontalCenter;

            // If horizontalCenter causes the box to overflow to the right, set left to be window size minus the spacing
            // Else set it to 0 plus the spacing
            if (horizontalCenter + selfHorizontalCenter + spacing >= windowWidth) {
                left = windowWidth - selfHorizontalCenter - spacing;
            } else if (horizontalCenter - selfHorizontalCenter - spacing <= 0) {
                left = selfHorizontalCenter + spacing;
            }

            // Calculate the offset of the triangle, relative to the card (self)
            const pointerOffset = 1 - (left + selfHorizontalCenter - horizontalCenter) / selfBounds.width;

            const targetVerticalCenter = targetBounds.top + (targetBounds.height / 2);
            const windowVerticalCenter = windowHeight / 2;
            const mountBelow = targetVerticalCenter <= windowVerticalCenter;

            return {
                // If mounted below target, add the targets height + spacing, else subtract the spacing
                top: targetBounds.top + (mountBelow ? (targetBounds.height + spacing) : -spacing),
                left,
                // Make sure it's between 0 and 1
                pointerOffset: Math.min(1, Math.max(0, pointerOffset)),
                mountBelow,
            };
        },
    },
};
</script>

<style lang="scss">
@mixin movement-transition($duration: .15s) {
    transition-timing-function: ease;
    transition-duration: $duration;
    transition-property: top, left;

    @media (prefers-reduced-motion) {
        transition: none;
    }
}

.introduction-card {
    top: 50%;
    left: 50%;
    width: 450px;
    max-width: calc(100% - 60px);
    margin: 0 auto;
    @include movement-transition;

    &.position-fixed {
        > svg {
            position: absolute;
            bottom: 0;
            width: 25%;
            max-width: 30px;
            height: auto;
            transform: translate(-50%, 100%);
            fill: $white;
            @include movement-transition;
        }

        &.mount-bottom {
            transform: translate(-50%, 0);

            > svg {
                top: 0;
                bottom: unset;
                transform: translate(-50%, -100%);
            }
        }
    }
}
</style>
